import { useId } from 'react';
import * as FormStyles from '../../styles/FormStyles';
import { CharactersRemaining, ErrorLabel, Label, TextInput, findError } from './Form';

export type onChangeEvent = (name: string, value: string) => void;
export type onFocusEvent = (name: string) => void;
export type onBlurEvent = () => void;

type LabeledInputParams = {
    name: string;
    label: string;
    value?: string | number | null;
    type?: string | null;
    placeholder?: string;
    maxLength?: number;
    required?: boolean;
    readonly?: boolean;
    disabled?: boolean;
    textInputClassName?: string;
    labelClassName?: string;
    containerClassName?: string;
    counterContainerClassName?: string;
    helperText?: string;
    formError?: unknown;
    formErrorName?: string;
    validationError?: string;
    showMax?: boolean;
    onChange?: onChangeEvent;
    onBlur?: onChangeEvent;
    onFocus?: onChangeEvent;
};

export default function LabeledInput(params: LabeledInputParams) {
    const inputType = params.type ?? 'text';
    const id = useId();

    const errors = params.validationError
        ? [params.validationError]
        : findError(params.formError, params.formErrorName ?? params.name);

    return (
        <section className={params.containerClassName ?? FormStyles.Field}>
            <Label name={id} required={params.required} className={params.labelClassName}>
                {params.label}
            </Label>

            <TextInput
                id={id}
                type={inputType}
                name={params.name}
                value={params.value ?? ''}
                placeholder={params.placeholder}
                maxLength={params.maxLength}
                readOnly={params.readonly}
                hasError={!!errors}
                disabled={params.disabled}
                className={params.textInputClassName}
                onChange={params.onChange}
                onBlur={params.onBlur}
                onFocus={params.onFocus}
            />
            {params.helperText && <div className="mt-2 text-sm">{params.helperText}</div>}
            {params.showMax && params.maxLength && params.maxLength > 0 && (
                <div className={`mt-3 ${params.counterContainerClassName}`}>
                    <CharactersRemaining count={(params.value as string)?.length ?? 0} max={params.maxLength} />
                </div>
            )}
            <ErrorLabel errors={errors} />
        </section>
    );
}
